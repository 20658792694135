import React from "react"
import SEO from "../../components/seo"

const PortfolioPage = () => {
  return (
    <main>
      <SEO title="Portfolio" />
    </main>
  )
}

export default PortfolioPage
